<template>
  <div class="page admission">
    <img class="logo" src="@/assets/img/philips_logo.png" alt="" />
    <div class="admission-map">
      <img src="@/assets/img/admission_map_new.png" alt="" />
    </div>
    <div class="admission-panel">
      <div class="admission-title">
        Experience <br />
        <b>Philips ventilation metaverse</b>
      </div>
      <div class="admission-description">
        Welcome to Philips ventilation metaverse where you can<br />
        experience the Trilogy Evo platform of ventilators in virtual<br />
        hospital and home settings.
      </div>

      <v-form ref="form" v-model="valid" class="admission-form" @submit.prevent="submitForm" lazy-validation>
        <div class="form-row">
          <v-text-field
            ref="name"
            outlined
            :rules="nameRules"
            type="text"
            placeholder="이름 (Full name)"
            v-model="name"
            maxlength="40"
          ></v-text-field>
        </div>
        <div class="form-row">
          <v-text-field
            ref="company"
            outlined
            :rules="[(v) => !!v || '병원을 입력하세요 This is a required field.']"
            type="text"
            placeholder="병원 (Organization)"
            v-model="company"
            maxlength="40"
          >
          </v-text-field>
        </div>
        <div class="form-row">
          <v-select
            outlined
            :rules="[(v) => !!v || '전문과목을 선택해 주세요 This is a required field.']"
            type="text"
            placeholder="전공분야 (Specialty)"
            v-model="department"
            :items="itemDepartment"
          />
        </div>
        <div class="form-row" v-if="department === '기타'">
          <v-text-field
            outlined
            :rules="[(v) => !!v || '진료과를 직접 입력해주세요.']"
            type="text"
            placeholder="기타입력 (Please specify it here)"
            v-model="departmentEtc"
            maxlength="50"
          >
          </v-text-field>
        </div>
        <div class="form-row agree-row">
          <v-checkbox
            dark
            :rules="[(v) => !!v || '개인정보 수집 및 이용 동의가 필요합니다. This is a required field.']"
            v-model="agree1"
            v-bind:true-value="true"
            v-bind:false-value="false"
            label=""
            color="primary"
            value="primary"
          >
            <template v-slot:label>
              <div class="custom-label">
                <a @click="clickPolicy('ko')">개인정보 수집 및 이용 동의</a><br />
                <a @click="clickPolicy('en')">Agree to our privacy policy</a>
              </div>
            </template>
          </v-checkbox>
          <!-- <v-btn dark text @click="clickPolicy"> 보기 </v-btn> -->
        </div>
        <div class="form-row agree-row">
          <v-checkbox
            dark
            :rules="[(v) => !!v || '개인정보 국외 이전 동의가 필요합니다. This is a required field.']"
            v-model="agree2"
            v-bind:true-value="true"
            v-bind:false-value="false"
            label=""
            color="primary"
            value="primary"
          >
            <template v-slot:label>
              <div class="custom-label">
                <a @click="clickPolicy('ko')">개인정보 국외 이전 동의</a><br />
                <a @click="clickPolicy('en')">Agree to personal data transfer between countries</a>
              </div>
            </template>
          </v-checkbox>
        </div>
        <div>
          <v-btn x-large depressed html-type="submit" class="admission-form-button" @click="submitForm">
            <b>간편 로그인 Simple sign-in</b>
          </v-btn>
        </div>
      </v-form>
    </div>
    <!-- Policy -->
    <DialogPolicy ref="DialogPolicy" />
  </div>
</template>

<script>
import { visitStartLogSave } from '@/api/auth';
import { visitCRUDModel } from '@/api/models/models';
import DialogPolicy from '@/components/common/Dialog/Dialog_Policy.vue';
import Vue from 'vue';
import { getItem, setItem } from '@/storage/storage';
export default {
  components: {
    DialogPolicy,
  },
  data() {
    return {
      valid: false,
      name: null,
      company: null,
      department: null,
      departmentEtc: null,
      itemDepartment: [
        {
          text: '중환자의학과 (Critical care)',
          value: '중환자의학과',
        },
        {
          text: '호흡기내과 (Pulmonology)',
          value: '호흡기내과',
        },
        {
          text: '재활의학과 (Rehabilitation)',
          value: '재활의학과',
        },
        {
          text: '응급의학과 (Emergency care)',
          value: '응급의학과',
        },
        {
          text: '신경과 (Neurology)',
          value: '신경과',
        },
        {
          text: '소아과 (Pediatrics)',
          value: '소아과',
        },
        {
          text: '순환기내과 (Cardiology)',
          value: '순환기내과',
        },
        {
          text: '마취통증의학과 (Anesthesiology)',
          value: '마취통증의학과 ',
        },
        {
          text: '외과(Surgery)',
          value: '외과',
        },
        {
          text: '가정의학과 (Family medicine)',
          value: '가정의학과',
        },
        {
          text: '종양학과 (Oncology)',
          value: '종양학과',
        },
        {
          text: '병리과 (Pathology)',
          value: '병리과',
        },
        {
          text: '영상의학과 (Radiology)',
          value: '영상의학과',
        },
        {
          text: '비뇨기과 (Urology)',
          value: '비뇨기과',
        },
        {
          text: '산부인과 (Obstetrics)',
          value: '산부인과',
        },
        {
          text: '기타-직접 기재 (Others-Please specify)',
          value: '기타',
        },
      ],
      agree1: false, // 개인정보 수집 및 이용 동의
      agree2: false, // 개인정보 국외 이전 동의
      agree: false, // 서버 전달용
      nameRules: [
        (v) => !!v || '성명을 입력하세요. This is a required field.',
        (v) => /^[|가-힣|a-z|A-Z|]([|가-힣|a-z|A-Z|\s]*[|가-힣|a-z|A-Z|])?$/.test(v) || '올바른 성함을 입력해주세요.',
      ],
    };
  },
  mounted() {
    // 개발 시 사용
    // this.name = '테스트';
    // this.company = '테스트 병원';
    // this.department = '종양학과';
    // this.agree1 = true;
    // this.agree2 = true;

    // 카카오 인앱 브라우저에서 입장 페이지 도달 시 타 웹브라우저로 오픈
    if (navigator.userAgent.indexOf('KAKAO') >= 0) {
      location.href =
        'kakaotalk://web/openExternal?url=' + encodeURIComponent('https://www.philipsmetaverse.co.kr/admission');
    }
  },
  methods: {
    async submitForm() {
      this.$refs.form.validate();
      // 유효성 검사
      const toast = (vaildation, message, type, focus) => {
        if (vaildation) {
          Vue.$toast.open({
            message,
            type,
            duration: 2000,
            dismissible: true,
          });
          focus();
          return true;
        } else {
          return false;
        }
      };

      const alertText =
        '이름, 병원, 전공분야를 모두 기입한 뒤 개인정보 정책 동의에 체크해 주세요. <br> Make sure to fill in the required fields and agree to our private information management policies.';
      const nameRule = !this.name || !/^[|가-힣|a-z|A-Z|]([|가-힣|a-z|A-Z|\s]*[|가-힣|a-z|A-Z|])?$/.test(this.name);
      const companyRule = !this.company;
      const departmentRule = !this.department || (this.department === '기타' && !this.departmentEtc);
      const agreeRule = !this.agree1 || !this.agree2;

      if (toast(nameRule, alertText, 'warning', this.$refs.name.focus)) return;
      if (toast(companyRule, alertText, 'warning', this.$refs.company.focus)) return;
      if (toast(departmentRule, alertText, 'warning', () => {})) return;
      if (toast(agreeRule, alertText, 'warning', () => {})) return;
      /** 고객사 요청 alert 주석 처리 - "개인정보 입력 후 입장하기만 하면 바로 입장할 수 있게 세팅+상단에 팝업 창 불필요(삭제)" **/
      // if (!confirm('입장하시겠습니까?')) {
      //   return;
      // }

      const visitData = new visitCRUDModel({
        name: this.name.trim(),
        company: this.company.trim(),
        department: this.department === '기타' ? `기타 (${this.departmentEtc.trim()})` : this.department.trim(),
        agree: this.agree1 && this.agree2 ? 'Y' : 'N',
      });
      console.log(visitData);
      // return;
      const data = await visitStartLogSave(visitData);

      if (data.error === '0') {
        // 고객사 요청으로 주석 처리함
        // Vue.$toast.open({
        //   message: `입장을 위한 정보 등록이 완료되었습니다.`,
        //   type: 'info',
        //   duration: 2000,
        //   dismissible: true,
        // });
        // 저장 성공시 메인 페이지로 이동 처리 필요
        this.$router.push({
          name: 'main',
          params: { enterFromAdmission: true },
        });
        // localStorage 에 저장 (계정정보)
        setItem('visiterInfo', { ...visitData, logIdx: data.logIndx });
        // socket connect
        // this.$socket.connect();
      } else {
        Vue.$toast.open({
          // message: data.message,
          message: '등록 실패',
          type: 'error',
          duration: 2000,
          dismissible: true,
        });
        return;
      }
    },
    clickPolicy(lang) {
      // this.$refs.DialogPolicy.Open();
      switch (lang) {
        case 'ko':
          window.open('https://www.philips.co.kr/a-w/privacy-notice', '(name)');
          break;
        case 'en':
          window.open('https://www.philips.com/a-w/privacy-notice', '(name)');
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 500px) and (max-height: 768px) {
  div.page.admission {
    background: linear-gradient(180deg, #02305c, #0874c4 80%);
    height: 100%;
    .admission-map {
      text-align: center;
      margin-top: 60px;
      img {
        width: 100%;
      }
    }
    .admission-panel {
      position: relative;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 20px 20px 80px;
      margin-left: 0;
      border-radius: 0;
      box-shadow: none;
      box-sizing: border-box;
      // overflow: auto;
      text-align: left;
      .admission-title {
        line-height: 40px;
        font-size: 35px;
      }
      .admission-description {
        line-height: 20px;
        margin: 15px 0 30px;
        br {
          display: none;
        }
      }
      .admission-form {
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}
@media (min-width: 501px) and (max-height: 600px) {
  div.page.admission {
    justify-content: start;
    height: auto;
    .admission-map {
      position: absolute;
      width: 100%;
      margin-top: 20px;
      img {
        opacity: 0.8;
        width: 50vw;
      }
    }
    .admission-panel {
      position: relative;
      width: 100%;
      // height: calc(100vh - 80px);
      top: 0;
      left: 0;
      padding: 20px 20px 80px;
      margin-top: 80px;
      margin-left: 0;
      border-radius: 0;
      box-shadow: none;
      box-sizing: border-box;
      // overflow: auto;
      text-align: left;
      .admission-title {
        line-height: 45px;
        font-size: 35px;
        margin-top: 0;
      }
      .admission-description {
        margin: 20px 0 35px;
      }

      .admission-form {
        margin: 0 auto;
        width: 100%;
      }
    }
  }
}
@media (min-width: 768px) {
  div.page.admission {
    height: auto;
    min-height: 100vh;
  }
}
.v-toast__item--warning {
  background-color: #da2b1f;
}
.page.admission {
  // background: linear-gradient(165deg, #02305c 30%, #0874c4);
  background: #02305c;
  flex-direction: column;
  display: flex;
  justify-content: center;
  height: 100vh;
  .logo {
    position: absolute;
    top: 0px;
    left: 20px;
    height: 100px;
    z-index: 500;
  }
  .admission-map {
    text-align: right;
    // margin-top: 100px;
    img {
      width: 55vw;
    }
  }
  .admission-panel {
    position: absolute;
    // background: #ffffff;
    padding: 40px 40px 40px;
    margin-left: 5%;
    border-radius: 10px;
    // box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    // height: calc(100% - 170px);
    .admission-logo-wrap {
      // padding: 40px;
      text-align: center;
      img {
        height: 70px;
      }
    }
    .admission-title {
      color: #ffffff;
      margin-top: 15px;
      font-size: 40px;
      line-height: 50px;
    }
    .admission-description {
      color: #ffffff;
      // padding: 15px;
      margin: 40px 0;
    }
    .admission-form {
      width: 420px;
      // padding: 10px;
      .form-row {
        // margin-bottom: 20px;
        position: relative;
        .v-input__slot {
          margin-bottom: 5px;
        }
        .v-text-field--outlined {
          & > .v-input__control > .v-input__slot {
            // background: rgba(241, 243, 246, 0.55);
            background: #ffffff;
            border-radius: 6px;
          }
          &:not(.error--text) fieldset {
            border-color: #e4e9f0;
          }
          input {
            color: $primary-color;
            max-height: initial;
            padding: 0;
            &::placeholder {
              color: #4e6087;
            }
          }
          .v-select__selections {
            color: $primary-color;
          }
        }
        &.agree-row {
          display: inline-flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          .v-input__slot {
            align-items: start;
            .v-icon.primary--text {
              background-color: #ffffff;
              border-radius: 3px;
            }
            .custom-label {
              font-size: 12px;
              a {
                display: inline-block;
                color: #ffffff;
                text-decoration: underline;
                margin-bottom: 5px;
              }
            }
          }
          .v-btn {
            text-decoration: underline;
          }
        }
      }
      .v-input--selection-controls {
        margin-top: 0;
      }
    }
    .admission-form-button {
      margin-top: 20px;
      width: 100%;
      background-color: #0072db;
      color: #ffffff;
      &:hover {
        background: #004da5;
        color: #97c7ff;
      }
    }
  }
}
</style>